import React from 'react';
// import AllRoute from '../router'
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import airplane from '../../images/picture-airplane.jpg'

const App = () => {

  return (
    <div className="App" id='scrool'>
      {/* <AllRoute />
      <ToastContainer /> */}
      <div style={{position: 'absolute', maxHeight: '100%', width: '44vh', top: '24vh', left: '40vw'}}>
        <img alt='icons' style={{maxWidth: '100%', borderRadius: '17px', boxShadow: '0 0 30px #000000a1'}} src={airplane} ></img>
        <div style={{fontSize: '30px', width: '200%', margin: '20px 0px 0px -54px'}}>Rogue Runways Coming Soon!</div>
      </div>
    </div>
    
  );
}

export default App;